import axios from '@axios'

export default {
  async getMittelPaar(dosID) {
    try {
      const r = await axios.get('sproc/getMittelEltern', {
        params: {
          dosID,
          istWiz: false,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getMittelPaarInsert(dosID) {
    try {
      const r = await axios.get('sproc/getMittelElternInsert', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putMittelPaar(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.pop()
      for (let i = 0; i < d.length; i += 1) {
        d[i].WertEF = (d[i].WertEF ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertEM = (d[i].WertEM ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].MittelEF = ''
        d[i].MittelEM = ''
        d[i].Info = ''
      }
      const r = await axios.get('sproc/getMittelElternUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putMittelPaarBem(dosID, bemEM, bemEF) {
    try {
      const r = await axios.get('sproc/getDossierUpdateMittelBem', {
        params: {
          dosID,
          bemEM: bemEM ?? '',
          bemEF: bemEF ?? '',
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getMittelKind(dosID) {
    try {
      const r = await axios.get('sproc/getMittelKind', {
        params: {
          dosID,
          istWiz: false,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getMittelKindInsert(dosID) {
    try {
      const r = await axios.get('sproc/getMittelKindInsert', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putMittelKind(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.pop()
      for (let i = 0; i < d.length; i += 1) {
        d[i].WertK1 = (d[i].WertK1 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK2 = (d[i].WertK2 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK3 = (d[i].WertK3 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK4 = (d[i].WertK4 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK5 = (d[i].WertK5 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK6 = (d[i].WertK6 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Info = ''
      }
      const r = await axios.get('sproc/getMittelKindUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBedarfPaar(dosID) {
    try {
      const r = await axios.get('sproc/getBedarfEltern', {
        params: {
          dosID,
          istErw: false,
          istWiz: false,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBedarfPaarInsert(dosID) {
    try {
      const r = await axios.get('sproc/getBedarfInsert', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putBedarfPaar(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.pop()
      for (let i = 0; i < d.length; i += 1) {
        d[i].WertEF = (d[i].WertEF ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertEM = (d[i].WertEM ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].BedarfEF = ''
        d[i].BedarfEM = ''
        d[i].Info = ''
      }
      const r = await axios.get('sproc/getBedarfElternUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putBedarfPaarBem(dosID, bemEM, bemEF) {
    try {
      const r = await axios.get('sproc/getDossierUpdateBedarfBem', {
        params: {
          dosID,
          bemEM: bemEM ?? '',
          bemEF: bemEF ?? '',
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getBedarfKind(dosID) {
    try {
      const r = await axios.get('sproc/getBedarfKind', {
        params: {
          dosID,
          istWiz: false,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBedarfKindInsert(dosID) {
    try {
      const r = await axios.get('sproc/getBedarfKindInsert', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putBedarfKind(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.pop()
      for (let i = 0; i < d.length; i += 1) {
        d[i].WertK1 = (d[i].WertK1 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK2 = (d[i].WertK2 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK3 = (d[i].WertK3 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK4 = (d[i].WertK4 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK5 = (d[i].WertK5 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].WertK6 = (d[i].WertK6 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Info = ''
      }
      const r = await axios.get('sproc/getBedarfKindUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
