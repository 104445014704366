<template>
    <b-tabs v-model="activeTab" @activate-tab="onTabChange"
    pills
  >
    <b-col cols="12" md="12">
      <h4>Info</h4>
      <blockquote>
        Wichtig: Die Wert sind pro Monat zu erfassen, zum Beispiel muss das Nettoeinkommen durch zwölf geteilt werden.
      </blockquote>
    </b-col>

    <b-tab active>
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $root.AnzahlKinder > 0 ? 'Eltern' : 'Paar' }}</span>
      </template>
      <mittel-paar class="mt-2 pt-75" />
    </b-tab>

    <b-tab v-if="$root.AnzahlKinder > 0">
      <template #title>
        <feather-icon
          icon="UserPlusIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $root.AnzahlKinder > 1 ? 'Kinder' : 'Kind' }}</span>
      </template>
      <mittel-kind class="mt-2 pt-75" />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs, BCol,
} from 'bootstrap-vue'
import db from '@/service/mittelbedarf'
import MittelPaar from './MittelPaar.vue'
import MittelKind from './MittelKind.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCol,

    MittelPaar,
    MittelKind,
  },
  props: {
    src: String,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  async created() {
    await db.getMittelPaarInsert(this.$root.currDosID)
    if (this.$root.AnzahlKinder > 0) await db.getMittelKindInsert(this.$root.currDosID)
  },
  methods: {
    // IMPORTANT: TO MAKE THE AUTOSAVE WORK, WINDOW.SAVEFORMDATA MUST MERELY BE SET IN THE FIRST TAB-FORM, OTHERWISE, IT'S NOT DEFINED WHICH FUNCTION-POINTER IS SET...
    async onTabChange(newIx, curIx) {
      if (curIx === 0) await this.$root.$refs.MITTELPAAR.save_changes(false)
      else if (curIx === 1) await this.$root.$refs.MITTELKIND.save_changes(false)

      if (newIx === 0) {
        window.saveFormData = this.$root.$refs.MITTELPAAR.save_changes
        await this.$root.$refs.MITTELPAAR.fetchData()
      } else if (newIx === 1) {
        window.saveFormData = this.$root.$refs.MITTELKIND.save_changes
        await this.$root.$refs.MITTELKIND.fetchData()
      }

      this.activeTab = newIx
    },
  },
}
</script>

<style>

</style>
